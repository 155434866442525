import actionTypes from './actionTypes'

export const actionFetchPesertaLulus = (data) => {
  return {
    type: actionTypes.FETCH_PESERTA_LULUS,
    data
  }
}

export const actionFetchPesertaLulusStart = () => {
  return {
    type: actionTypes.FETCH_PESERTA_LULUS_START
  }
}

export const actionFetchPesertaLulusEnd = () => {
  return {
    type: actionTypes.FETCH_PESERTA_LULUS_END
  }
}

export const actionFetchPesertaLulusSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PESERTA_LULUS_SUCCESS,
    data
  }
}

export const actionCreatePesertaLulus = (data) => {
  return {
    type: actionTypes.CREATE_PESERTA_LULUS,
    data
  }
}

export const actionCreatePesertaLulusStart = () => {
  return {
    type: actionTypes.CREATE_PESERTA_LULUS_START
  }
}

export const actionCreatePesertaLulusEnd = () => {
  return {
    type: actionTypes.CREATE_PESERTA_LULUS_END
  }
}

export const actionCreatePesertaLulusSuccess = (data) => {
  return {
    type: actionTypes.CREATE_PESERTA_LULUS_SUCCESS,
    data
  }
}

export const actionCreatePesertaLulusFailed = (data) => {
  return {
    type: actionTypes.CREATE_PESERTA_LULUS_FAILED,
    data
  }
}

export const actionUpdatePesertaLulus = (data) => {
  return {
    type: actionTypes.UPDATE_PESERTA_LULUS,
    data
  }
}

export const actionUpdatePesertaLulusStart = () => {
  return {
    type: actionTypes.UPDATE_PESERTA_LULUS_START
  }
}

export const actionUpdatePesertaLulusEnd = () => {
  return {
    type: actionTypes.UPDATE_PESERTA_LULUS_END
  }
}

export const actionUpdatePesertaLulusSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_PESERTA_LULUS_SUCCESS,
    data
  }
}

export const actionUpdatePesertaLulusFailed = (data) => {
  return {
    type: actionTypes.UPDATE_PESERTA_LULUS_FAILED,
    data
  }
}

export const actionResetFormPesertaLulus = () => {
  return {
    type: actionTypes.RESET_FORM_PESERTA_LULUS
  }
}

export const actionDeletePesertaLulus = (data) => {
  return {
    type: actionTypes.DELETE_PESERTA_LULUS,
    data
  }
}

export const actionDeletePesertaLulusStart = () => {
  return {
    type: actionTypes.DELETE_PESERTA_LULUS_START
  }
}

export const actionDeletePesertaLulusEnd = () => {
  return {
    type: actionTypes.DELETE_PESERTA_LULUS_END
  }
}

export const actionDeletePesertaLulusSuccess = () => {
  return {
    type: actionTypes.DELETE_PESERTA_LULUS_SUCCESS
  }
}

export const actionDeletePesertaLulusFailed = () => {
  return {
    type: actionTypes.DELETE_PESERTA_LULUS_FAILED
  }
}

export const actionResetDeletePesertaLulus = () => {
  return {
    type: actionTypes.RESET_DELETE_PESERTA_LULUS
  }
}

export const actionImportPesertaLulus = (data) => {
  return {
    type: actionTypes.IMPORT_PESERTA_LULUS,
    data
  }
}

export const actionImportPesertaLulusStart = () => {
  return {
    type: actionTypes.IMPORT_PESERTA_LULUS_START
  }
}

export const actionImportPesertaLulusEnd = () => {
  return {
    type: actionTypes.IMPORT_PESERTA_LULUS_END
  }
}

export const actionImportPesertaLulusSuccess = (data) => {
  return {
    type: actionTypes.IMPORT_PESERTA_LULUS_SUCCESS,
    data
  }
}

export const actionImportPesertaLulusFailed = (data) => {
  return {
    type: actionTypes.IMPORT_PESERTA_LULUS_FAILED,
    data
  }
}