import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import AuthLayout from "./components/Layout/AuthLayout"
import NonAuthLayout from "./components/Layout/NonAuthLayout"
import ErrorLayout from "./components/Layout/ErrorLayout"
import { useSelector } from 'react-redux'
import Page404 from './pages/Error/Page404'
import SignIn from './pages/SignIn'
import SignOut from './pages/SignOut'
import Beranda from './pages/Beranda'

import SesiUjianIndex from './pages/SesiUjian/index'
import SesiUjianCreate from './pages/SesiUjian/create'
import SesiUjianDetail from './pages/SesiUjian/detail'
import SesiUjianUpdate from './pages/SesiUjian/update'

import PesertaUjianIndex from './pages/PesertaUjian/index'
import PesertaUjianCreate from './pages/PesertaUjian/create'
import PesertaUjianDetail from './pages/PesertaUjian/detail'
import PesertaUjianUpdate from './pages/PesertaUjian/update'
import PesertaUjianImport from './pages/PesertaUjian/import'

import PesertaLulusIndex from './pages/PesertaLulus/index'
import PesertaLulusCreate from './pages/PesertaLulus/create'
import PesertaLulusDetail from './pages/PesertaLulus/detail'
import PesertaLulusUpdate from './pages/PesertaLulus/update'
import PesertaLulusImport from './pages/PesertaLulus/import'

import SoalUjianIndex from './pages/SoalUjian/index'
import SoalUjianCreate from './pages/SoalUjian/create'
import SoalUjianDetail from './pages/SoalUjian/detail'
import SoalUjianUpdate from './pages/SoalUjian/update'
import SoalUjianImport from './pages/SoalUjian/import'

import KategoriSoalIndex from './pages/KategoriSoal/index'
import KategoriSoalCreate from './pages/KategoriSoal/create'
import KategoriSoalDetail from './pages/KategoriSoal/detail'
import KategoriSoalUpdate from './pages/KategoriSoal/update'

import PesertaDaftarUlangIndex from './pages/PesertaDaftarUlang/index'
import PesertaDaftarUlangDetail from './pages/PesertaDaftarUlang/detail'
import PesertaDaftarUlangUpdate from './pages/PesertaDaftarUlang/update'

import UjianPesertaIndex from './pages/UjianPeserta/index'
import UjianPesertaDetail from './pages/UjianPeserta/detail'

import PeringkatUjianIndex from './pages/PeringkatUjian/index'
import PeringkatUjianDetail from './pages/PeringkatUjian/detail'

import LaporanPesertaIndex from './pages/LaporanPeserta/index'
import LaporanPesertaDetail from './pages/LaporanPeserta/detail'

const App = ({ childProps }) => {
  return (
    <Switch>
      <Route exact path={"/"} render={() => <Redirect to={process.env.REACT_APP_SUBDIR + '/beranda'} />} />
      <Route exact path={process.env.REACT_APP_SUBDIR + "/"} render={() => <Redirect to={process.env.REACT_APP_SUBDIR + '/beranda'} />} />

      <NonAuthRoute path={process.env.REACT_APP_SUBDIR + "/sign-in"} component={SignIn} props={childProps} />

      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/beranda"} component={Beranda} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/sesi-ujian"} component={SesiUjianIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/sesi-ujian/create"} component={SesiUjianCreate} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/sesi-ujian/detail/:id"} component={SesiUjianDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/sesi-ujian/update/:id"} component={SesiUjianUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/soal-ujian"} component={SoalUjianIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/soal-ujian/create"} component={SoalUjianCreate} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/soal-ujian/import"} component={SoalUjianImport} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/soal-ujian/detail/:id"} component={SoalUjianDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/soal-ujian/update/:id"} component={SoalUjianUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/kategori-soal"} component={KategoriSoalIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/kategori-soal/create"} component={KategoriSoalCreate} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/kategori-soal/detail/:id"} component={KategoriSoalDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/kategori-soal/update/:id"} component={KategoriSoalUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/peserta-ujian"} component={PesertaUjianIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/peserta-ujian/create"} component={PesertaUjianCreate} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/peserta-ujian/import"} component={PesertaUjianImport} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/peserta-ujian/detail/:id"} component={PesertaUjianDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/peserta-ujian/update/:id"} component={PesertaUjianUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/peserta-lulus"} component={PesertaLulusIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/peserta-lulus/create"} component={PesertaLulusCreate} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/peserta-lulus/import"} component={PesertaLulusImport} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/peserta-lulus/detail/:id"} component={PesertaLulusDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/peserta-lulus/update/:id"} component={PesertaLulusUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/peserta-daftar-ulang"} component={PesertaDaftarUlangIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/peserta-daftar-ulang/detail/:id"} component={PesertaDaftarUlangDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/peserta-daftar-ulang/update/:id"} component={PesertaDaftarUlangUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/ujian-peserta"} component={UjianPesertaIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/ujian-peserta/detail/:id"} component={UjianPesertaDetail} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/peringkat-ujian"} component={PeringkatUjianIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/peringkat-ujian/detail/:id"} component={PeringkatUjianDetail} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/laporan-peserta-belum-daftar-ulang"} component={LaporanPesertaIndex} props={childProps} />
      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/laporan-peserta-daftar-ulang"} component={LaporanPesertaIndex} props={childProps} />
      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/laporan-peserta-ikut-ujian"} component={LaporanPesertaIndex} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/laporan-peserta-belum-daftar-ulang/detail/:id"} component={LaporanPesertaDetail} props={childProps} />
      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/laporan-peserta-daftar-ulang/detail/:id"} component={LaporanPesertaDetail} props={childProps} />
      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/laporan-peserta-ikut-ujian/detail/:id"} component={LaporanPesertaDetail} props={childProps} />

      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/sign-out"} component={SignOut} props={childProps} />

      <ErrorRoute path='*' exact={true} component={Page404} />
    </Switch>
  )
}

const ErrorRoute = ({ component: Component, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <ErrorLayout>
          <Component {...props} />
        </ErrorLayout>
      )}
    />
  )
}

const NonAuthRoute = ({ component: Component, location, ...rest }) => {
  const auth = useSelector(state => state.auth)
  let pass = false

  if (auth?.user) {
    const now = parseInt((new Date).getTime() / 1000)
    const sisaWaktu = auth?.expiredAt - now

    if (sisaWaktu > 0) {
      pass = true
    }
  }

  return (
    <Route
      {...rest}
      render={props => (
        pass === true ?
          // <NonAuthLayout>
          //   <Component {...props} />
          // </NonAuthLayout>
          <Redirect
            to={{
              pathname: process.env.REACT_APP_SUBDIR + '/beranda',
              state: { from: props.location }
            }}
          />
          :
          <NonAuthLayout>
            <Component {...props} />
          </NonAuthLayout>
      )}
    />
  )
}

const AuthRoute = ({ component: Component, location, ...rest }) => {
  const auth = useSelector(state => state.auth)
  let pass = false

  if (auth && auth.user) {
    pass = true
  }

  return (
    <Route
      {...rest}
      render={props => (
        pass === true ?
          <AuthLayout>
            <Component {...props} />
          </AuthLayout>
          :
          <Redirect
            to={{
              pathname: process.env.REACT_APP_SUBDIR + '/sign-in',
              state: { from: props.location }
            }} />
      )}
    />
  )
}

export default App