export const changeTitle = (title) => {
    return document.title = `${title || '-'} | P3TGAI`
}

export const changeBreadcrumbs = (title) => {
    let breadcrumbTitle = document.getElementById("breadcrumbTitle")
    if (breadcrumbTitle)
        breadcrumbTitle.innerHTML = title

    return true
}