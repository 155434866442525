import Immutable from 'seamless-immutable'

const FETCH_PESERTA_LULUS = 'FETCH_PESERTA_LULUS'
const FETCH_PESERTA_LULUS_START = 'FETCH_PESERTA_LULUS_START'
const FETCH_PESERTA_LULUS_END = 'FETCH_PESERTA_LULUS_END'
const FETCH_PESERTA_LULUS_SUCCESS = 'FETCH_PESERTA_LULUS_SUCCESS'
const FETCH_PESERTA_LULUS_FAILED = 'FETCH_PESERTA_LULUS_FAILED'

const IMPORT_PESERTA_LULUS = 'IMPORT_PESERTA_LULUS'
const IMPORT_PESERTA_LULUS_START = 'IMPORT_PESERTA_LULUS_START'
const IMPORT_PESERTA_LULUS_END = 'IMPORT_PESERTA_LULUS_END'
const IMPORT_PESERTA_LULUS_SUCCESS = 'IMPORT_PESERTA_LULUS_SUCCESS'
const IMPORT_PESERTA_LULUS_FAILED = 'IMPORT_PESERTA_LULUS_FAILED'

const CREATE_PESERTA_LULUS = 'CREATE_PESERTA_LULUS'
const CREATE_PESERTA_LULUS_START = 'CREATE_PESERTA_LULUS_START'
const CREATE_PESERTA_LULUS_END = 'CREATE_PESERTA_LULUS_END'
const CREATE_PESERTA_LULUS_SUCCESS = 'CREATE_PESERTA_LULUS_SUCCESS'
const CREATE_PESERTA_LULUS_FAILED = 'CREATE_PESERTA_LULUS_FAILED'

const UPDATE_PESERTA_LULUS = 'UPDATE_PESERTA_LULUS'
const UPDATE_PESERTA_LULUS_START = 'UPDATE_PESERTA_LULUS_START'
const UPDATE_PESERTA_LULUS_END = 'UPDATE_PESERTA_LULUS_END'
const UPDATE_PESERTA_LULUS_SUCCESS = 'UPDATE_PESERTA_LULUS_SUCCESS'
const UPDATE_PESERTA_LULUS_FAILED = 'UPDATE_PESERTA_LULUS_FAILED'

const DELETE_PESERTA_LULUS = 'DELETE_PESERTA_LULUS'
const DELETE_PESERTA_LULUS_START = 'DELETE_PESERTA_LULUS_START'
const DELETE_PESERTA_LULUS_END = 'DELETE_PESERTA_LULUS_END'
const DELETE_PESERTA_LULUS_SUCCESS = 'DELETE_PESERTA_LULUS_SUCCESS'
const DELETE_PESERTA_LULUS_FAILED = 'DELETE_PESERTA_LULUS_FAILED'

const RESET_FORM_PESERTA_LULUS = 'RESET_FORM_PESERTA_LULUS'
const RESET_DELETE_PESERTA_LULUS = 'RESET_DELETE_PESERTA_LULUS'

const actionTypes = Immutable({
  FETCH_PESERTA_LULUS,
  FETCH_PESERTA_LULUS_START,
  FETCH_PESERTA_LULUS_END,
  FETCH_PESERTA_LULUS_SUCCESS,
  FETCH_PESERTA_LULUS_FAILED,

  IMPORT_PESERTA_LULUS,
  IMPORT_PESERTA_LULUS_START,
  IMPORT_PESERTA_LULUS_END,
  IMPORT_PESERTA_LULUS_SUCCESS,
  IMPORT_PESERTA_LULUS_FAILED,

  CREATE_PESERTA_LULUS,
  CREATE_PESERTA_LULUS_START,
  CREATE_PESERTA_LULUS_END,
  CREATE_PESERTA_LULUS_SUCCESS,
  CREATE_PESERTA_LULUS_FAILED,

  UPDATE_PESERTA_LULUS,
  UPDATE_PESERTA_LULUS_START,
  UPDATE_PESERTA_LULUS_END,
  UPDATE_PESERTA_LULUS_SUCCESS,
  UPDATE_PESERTA_LULUS_FAILED,

  DELETE_PESERTA_LULUS,
  DELETE_PESERTA_LULUS_START,
  DELETE_PESERTA_LULUS_END,
  DELETE_PESERTA_LULUS_SUCCESS,
  DELETE_PESERTA_LULUS_FAILED,

  RESET_FORM_PESERTA_LULUS,
  RESET_DELETE_PESERTA_LULUS
})

export default actionTypes
