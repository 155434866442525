import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

const Menu = () => {
    const [items, setItems] = useState([])
    const [arrLocation, setArrLocation] = useState(null)

    const location = useLocation();

    useEffect(() => {
        let arrLocation = location?.pathname?.split("/")

        if (arrLocation?.length > 0) {
            setArrLocation(arrLocation)
        }
    }, [location])

    useEffect(() => {
        let arrItems = [
            {
                type: "menu",
                label: "Beranda",
                url: "/beranda",
                icon: "bx bx-home-circle"
            },
            {
                label: "Master",
                type: "divider"
            },
            {
                type: "menu",
                label: "Sesi Ujian",
                url: "/sesi-ujian",
                icon: "bx bx-time"
            },
            {
                type: "menu",
                label: "Kategori Soal",
                url: "/kategori-soal",
                icon: "bx bx-list-ul"
            },
            {
                type: "menu",
                label: "Soal Ujian",
                url: "/soal-ujian",
                icon: "bx bx-copy-alt"
            },
            {
                type: "menu",
                label: "Hasil Seleksi",
                url: "/peserta-ujian",
                icon: "bx bx-user"
            },
            {
                label: "Ujian",
                type: "divider"
            },
            {
                type: "menu",
                label: "Peserta Daftar Ulang",
                url: "/peserta-daftar-ulang",
                icon: "bx bx-user-check"
            },
            {
                type: "menu",
                label: "Ujian Peserta",
                url: "/ujian-peserta",
                icon: "bx bx-task"
            },
            {
                type: "menu",
                label: "Peserta Lulus",
                url: "/peserta-lulus",
                icon: "bx bx-upload"
            },
            // {
            //     type: "menu",
            //     label: "Verifikasi Peserta",
            //     url: "/verifikas-peserta",
            //     icon: "bx bxs-user-rectangle"
            // },
            {
                label: "Laporan",
                type: "divider"
            },
            {
                type: "menu",
                label: "Peringkat Ujian",
                url: "/peringkat-ujian",
                icon: "bx bx-line-chart"
            },
            {
                type: "parent",
                label: "Hasil Seleksi",
                icon: "bx bx-user-check",
                childs: [
                    { label: "Belum Daftar Ulang", url: "/laporan-peserta-belum-daftar-ulang" },
                    { label: "Calon Peserta Ujian", url: "/laporan-peserta-daftar-ulang" },
                    { label: "Peserta Ujian", url: "/laporan-peserta-ikut-ujian" },
                ]
            }
        ]

        // arrItems = [
        //     {
        //         type: "parent",
        //         label: "Peserta",
        //         icon: "bx bx-user-check",
        //         childs: [
        //             { label: "Daftar Ulang", url: "/laporan-peserta-daftar-ulang" },
        //             { label: "Tidak Daftar Ulang", url: "/laporan-peserta-tidak-daftar-ulang" },
        //             { label: "Ikut Ujian", url: "/laporan-peserta-ikut-ujian" },
        //         ]
        //     }
        // ]

        setItems(arrItems)
    }, [])

    return <React.Fragment>
        <ul className="menu-inner py-1">
            {
                items?.map((item, key) => {
                    if (item?.type === "parent") {
                        return <li key={key} className={"menu-item" + (item?.childs?.filter((x) => x?.url === "/" + arrLocation[1])?.length > 0 ? " active open" : "")}>
                            <a href="/#" onClick={(e) => e.preventDefault()} className="menu-link menu-toggle">
                                <i className={"menu-icon tf-icons " + item?.icon}></i>
                                <div>{item?.label}</div>
                            </a>
                            <ul className="menu-sub">
                                {
                                    item?.childs?.map((item2, key2) => {
                                        return <li key={key2} className={"menu-item" + (item2?.url === "/" + arrLocation[1] ? " active" : "")}>
                                            <Link to={item2?.url} className="menu-link">
                                                <div>{item2?.label}</div>
                                            </Link>
                                        </li>
                                    })
                                }
                            </ul>
                        </li>
                    } else if (item?.type === "menu") {
                        return <li key={key} className={"menu-item" + (item?.url === "/" + arrLocation[1] ? " active" : "")}>
                            <Link to={item?.url} className="menu-link">
                                <i className={"menu-icon tf-icons " + item?.icon}></i>
                                <div>{item?.label}</div>
                            </Link>
                        </li>
                    } else if (item?.type === "divider") {
                        return <li key={key} className="menu-header small text-uppercase">
                            <span className="menu-header-text">{item?.label}</span>
                        </li>
                    }
                })
            }
        </ul>
    </React.Fragment>
}

export default Menu