import React from "react"
import { Link } from "react-router-dom"
import { initialName } from "../../../utils/formatter"
import { pushUrl } from "../../../utils/url"
import AvatarByName from "../../AvatarByName"
import { request } from '../../../utils/request'

const Navbar = ({ user, accessToken, notificationList = [], onClickSuccess }) => {
    const clickNotif = (val) => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            request(process.env.REACT_APP_SERVICE_NOTIFIKASI + '/' + val?.id, {
                method: 'GET',
                headers: header
            })
        } catch (error) { }

        let index = notificationList.findIndex(e => e.id === val?.id)
        let newNotifArr = index > 0 ? notificationList?.splice(index, 1) : []

        onClickSuccess(newNotifArr)
        pushUrl("/" + val?.url)
    }

    return <React.Fragment>
        <ul className="navbar-nav flex-row align-items-center ms-auto">
            <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
                <a className="nav-link dropdown-toggle hide-arrow" href="/#" onClick={(e) => e.preventDefault()} data-bs-toggle="dropdown"
                    data-bs-auto-close="outside" aria-expanded="false">
                    <i className="bx bx-bell bx-sm"></i>
                    {
                        notificationList?.length > 0 ?
                            <span className="badge bg-danger rounded-pill badge-notifications">{notificationList?.length}</span>
                            : null
                    }
                </a>
                <ul className="dropdown-menu dropdown-menu-end py-0">
                    <li className="dropdown-menu-header border-bottom">
                        <div className="dropdown-header d-flex align-items-center py-3">
                            <h5 className="text-body mb-0 me-auto">Pemberitahuan</h5>
                            {
                                // notificationList?.length > 0 ?
                                //     <a href="/#" onClick={(e) => e.preventDefault()} className="dropdown-notifications-all text-body" data-bs-toggle="tooltip"
                                //         data-bs-placement="top" title="Tandai semua sudah dibaca"><i className="bx fs-4 bx-envelope-open"></i></a>
                                //     : null
                            }
                        </div>
                    </li>
                    <li className="dropdown-notifications-list scrollable-container">
                        <ul className="list-group list-group-flush">
                            {
                                notificationList?.length > 0 ?
                                    notificationList?.map((val, key) => {
                                        return <li key={key} className="list-group-item list-group-item-action dropdown-notifications-item" onClick={() => clickNotif(val)}>
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar">
                                                        <span className={"avatar-initial rounded-circle bg-label-" + (key % 2 === 0 ? "primary" : "secondary")}>{initialName(val?.judul)}</span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h6 className="mb-1">{val?.judul}</h6>
                                                    <p className="mb-0">{val?.detail}</p>
                                                    <small className="text-muted">{val?.time}</small>
                                                </div>
                                            </div>
                                        </li>
                                    })
                                    : <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                        Tidak ada pemberitahuan
                                    </li>
                            }
                        </ul>
                    </li>
                    {
                        // notificationList?.length > 0 ?
                        //     <li className="dropdown-menu-footer border-top">
                        //         <a href="/#" onClick={(e) => e.preventDefault()} className="dropdown-item d-flex justify-content-center p-3">
                        //             Lihat semua
                        //         </a>
                        //     </li>
                        //     : null
                    }
                </ul>
            </li>
            <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <a className="nav-link dropdown-toggle hide-arrow" href="/#" onClick={(e) => e.preventDefault()} data-bs-toggle="dropdown">
                    <div className="avatar avatar-online">
                        <AvatarByName name={initialName(user?.name)} />
                    </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                        <a className="dropdown-item" href="pages-account-settings-account.html">
                            <div className="d-flex">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar avatar-online">
                                        <AvatarByName name={initialName(user?.name)} />
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <span className="fw-semibold d-block">{user?.name}</span>
                                    <small className="text-muted">{user?.role}</small>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <div className="dropdown-divider"></div>
                    </li>
                    <li>
                        <Link to={`${process.env.REACT_APP_SUBDIR}/sign-out`} className="dropdown-item">
                            <i className="bx bx-power-off me-2"></i>
                            <span className="align-middle">Log Out</span>
                        </Link>
                    </li>
                </ul>
            </li>
        </ul>
    </React.Fragment>
}

export default Navbar