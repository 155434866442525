import React, { useEffect, useState } from "react"
import { changeBreadcrumbs, changeTitle } from "../utils/page"
import IlsBeranda from "../assets/img/illustrations/man-with-laptop-light.png"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { request } from "../utils/request"
import { refreshDashboard } from "../utils/config"
import { dateTime, strtotime } from "../utils/formatter"
import HeadTable from "../components/Table/HeadTable"

const Beranda = (props) => {
    const { auth } = props

    const [data, setData] = useState({
        total: {
            daftarUlangHarian: 0,
            soalUjian: 0,
            kategoriSoal: 0,
            sesiUjian: 0,
            hasilSeleksi: 0,
            daftarUlang: 0,
            pesertaTanpaSesi: 0,
            ujianPeserta: 0
        },
        list: {
            ujianPerSesi: []
        }
    })

    useEffect(() => {
        const titlePage = "Beranda"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        actionFetchDashboard()

        if (refreshDashboard === true) {
            let intervalId = setInterval(() => actionFetchDashboard(), 30000)

            return () => clearInterval(intervalId)
        }
    }, [])

    const actionFetchDashboard = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${auth?.accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_DASHBOARD + '/main', {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setData(res?.result)
            }
        } catch (error) { }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-8 mb-2 order-0">
                    <div className="card mb-4">
                        <div className="d-flex align-items-end row">
                            <div className="col-sm-7">
                                <div className="card-body">
                                    <h5 className="card-title text-primary">Selamat Datang{" " + auth?.user?.name}! 🎉</h5>
                                    {
                                        data?.total?.daftarUlangHarian > 0 ?
                                            <p className="mb-4">{data?.total?.daftarUlangHarian} calon peserta ujian daftar ulang hari ini.</p>
                                            :
                                            <p className="mb-4">Tidak ada calon peserta ujian daftar ulang hari ini.</p>
                                    }
                                    <Link to={"/peserta-daftar-ulang?search=" + dateTime(strtotime(new Date()), "YYYY-MM-DD")} className="btn btn-sm btn-label-primary">Lihat Peserta</Link>
                                </div>
                            </div>
                            <div className="col-sm-5 text-center text-sm-left">
                                <div className="card-body pb-0 px-0 px-md-4">
                                    <img src={IlsBeranda} height="140" alt="View Badge User" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-user-check" style={{ fontSize: "3.2rem", color: "#354777" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Peserta Daftar Ulang</span>
                                    <h4 className="card-title mb-1">{data?.total?.daftarUlang || 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-user-minus" style={{ fontSize: "3.2rem", color: "#fec827" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Peserta Tanpa Sesi</span>
                                    <h4 className="card-title mb-1">{data?.total?.pesertaTanpaSesi || 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-task" style={{ fontSize: "3.2rem", color: "#354777" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Ujian Peserta</span>
                                    <h4 className="card-title mb-1">{data?.total?.ujianPeserta || 0}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mb-2 order-1">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-time" style={{ fontSize: "3.2rem", color: "#354777" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Sesi Ujian</span>
                                    <h4 className="card-title mb-1">{data?.total?.sesiUjian || 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-list-ul" style={{ fontSize: "3.2rem", color: "#fec827" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Kategori Soal</span>
                                    <h4 className="card-title mb-1">{data?.total?.kategoriSoal || 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-copy-alt" style={{ fontSize: "3.2rem", color: "#fec827" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Soal Ujian</span>
                                    <h4 className="card-title mb-1">{data?.total?.soalUjian || 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-user" style={{ fontSize: "3.2rem", color: "#354777" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Hasil Seleksi</span>
                                    <h4 className="card-title mb-1">{data?.total?.hasilSeleksi || 0}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card pt-0">
                <div className="card-body">
                    <small className="text-muted text-uppercase">Total Ujian Per Sesi</small>
                    <div className="table-responsive grid-view mt-2">
                        <table className="table table-bordered">
                            <HeadTable
                                colorized={false}
                                columns={[
                                    { type: "label", label: "Nama" },
                                    { type: "label", label: "Waktu Mulai" },
                                    { type: "label", label: "Waktu Selesai" },
                                    { type: "label", label: "Batas Peserta" },
                                    { type: "label", label: "Jumlah Peserta" },
                                ]}
                            />
                            <tbody className="table-border-bottom-0">
                                {
                                    data?.list?.ujianPerSesi?.map((val, key) => {
                                        return <React.Fragment key={key}>
                                            <tr className={(val?.total > val?.batas_peserta ? "table-warning" : "")}>
                                                <td>{val?.nama}</td>
                                                <td>{val?.waktu_mulai}</td>
                                                <td>{val?.waktu_selesai}</td>
                                                <td>{val?.batas_peserta}</td>
                                                <td style={{ width: "100px" }}>{val?.total}</td>
                                            </tr>
                                        </React.Fragment>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment >
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

export default withRouter(connect(mapStateToProps, {})(Beranda))