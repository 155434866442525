import { takeEvery, put, all, call, select } from 'redux-saga/effects'
import actionTypes from './actionTypes'
import { request } from '../../utils/request'
import { alert } from '../../utils/alert'
import { actionUpdatePesertaLulusEnd, actionUpdatePesertaLulusFailed, actionUpdatePesertaLulusStart, actionUpdatePesertaLulusSuccess, actionCreatePesertaLulusEnd, actionCreatePesertaLulusFailed, actionCreatePesertaLulusStart, actionCreatePesertaLulusSuccess, actionFetchPesertaLulusEnd, actionFetchPesertaLulusStart, actionFetchPesertaLulusSuccess, actionDeletePesertaLulusSuccess, actionDeletePesertaLulusFailed, actionDeletePesertaLulusStart, actionDeletePesertaLulusEnd, actionImportPesertaLulusEnd, actionImportPesertaLulusFailed, actionImportPesertaLulusSuccess, actionImportPesertaLulusStart } from './actions'
import { pushUrl } from '../../utils/url'

function* sagaFetchPesertaLulus(params) {
  yield put(actionFetchPesertaLulusStart())

  const { data: { accessToken, search, page, sort, perPage } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let sortParam = ""
    if (sort?.by) {
      sortParam += "&sortBy=" + sort?.by
    }
    if (sort?.type) {
      sortParam += "&sortType=" + sort?.type
    }

    let perPageParam = perPage ? "&perPage=" + perPage : ""

    let pageParam = page ? "?page=" + page : "?page=1"

    let searchParam = search ? "&search=" + search : ""

    let url = `${process.env.REACT_APP_SERVICE_PESERTA_LULUS}${pageParam}${searchParam}${sortParam}${perPageParam}`
    const res = yield call(request, url, {
      method: 'GET',
      headers: header
    })

    if (res?.success === true) {
      let data = {
        list: res?.result?.data,
        page: res?.result?.current_page,
        pageUrl: {
          first_page_url: res?.result?.first_page_url,
          links: res?.result?.links,
          last_page_url: res?.result?.last_page_url
        },
        last_page: res?.result?.last_page,
        from: res?.result?.from,
        to: res?.result?.to,
        total: res?.result?.total
      }
      yield put(actionFetchPesertaLulusSuccess(data))
    } else {
      alert({ title: "Gagal", html: res?.message })
    }
  } catch (err) {
    alert({ title: "Gagal" })
    // yield put(actionSignInFailed())
  }

  yield put(actionFetchPesertaLulusEnd())
}

function* sagaCreatePesertaLulus(params) {
  yield put(actionCreatePesertaLulusStart())

  const { data: { accessToken, payload } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_PESERTA_LULUS}`
    const res = yield call(request, url, {
      method: 'POST',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionCreatePesertaLulusSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionCreatePesertaLulusFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal menambah data",
      errors: null
    }

    yield put(actionCreatePesertaLulusFailed(data))
  }

  yield put(actionCreatePesertaLulusEnd())
}

function* sagaImportPesertaLulus(params) {
  yield put(actionImportPesertaLulusStart())

  const { data: { accessToken, payload } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_PESERTA_LULUS}/import`
    const res = yield call(request, url, {
      method: 'POST',
      headers: headerPost,
      body: JSON.stringify({ payload })
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionImportPesertaLulusSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionImportPesertaLulusFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal import data",
      errors: null
    }

    yield put(actionImportPesertaLulusFailed(data))
  }

  yield put(actionImportPesertaLulusEnd())
}

function* sagaUpdatePesertaLulus(params) {
  yield put(actionUpdatePesertaLulusStart())

  const { data: { accessToken, payload, id } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_PESERTA_LULUS}/${id}`
    const res = yield call(request, url, {
      method: 'PUT',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionUpdatePesertaLulusSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionUpdatePesertaLulusFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal merubah data",
      errors: null
    }

    yield put(actionUpdatePesertaLulusFailed(data))
  }

  yield put(actionUpdatePesertaLulusEnd())
}

function* sagaDeletePesertaLulus(params) {
  yield put(actionDeletePesertaLulusStart())

  const { data: { accessToken, id } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_PESERTA_LULUS}/${id}`
    const res = yield call(request, url, {
      method: 'DELETE',
      headers: header
    })

    if (res?.success === true) {
      yield put(actionDeletePesertaLulusSuccess())
    } else {
      yield put(actionDeletePesertaLulusFailed())
    }
  } catch (err) {
    yield put(actionDeletePesertaLulusFailed())
  }

  yield put(actionDeletePesertaLulusEnd())
}

function* pesertaLulusSaga() {
  yield all([
    takeEvery(actionTypes.FETCH_PESERTA_LULUS, sagaFetchPesertaLulus),
    takeEvery(actionTypes.CREATE_PESERTA_LULUS, sagaCreatePesertaLulus),
    takeEvery(actionTypes.UPDATE_PESERTA_LULUS, sagaUpdatePesertaLulus),
    takeEvery(actionTypes.DELETE_PESERTA_LULUS, sagaDeletePesertaLulus),
    takeEvery(actionTypes.IMPORT_PESERTA_LULUS, sagaImportPesertaLulus)
  ])
}

export default pesertaLulusSaga