import { all } from 'redux-saga/effects'
import authSaga from './auth/saga'
import sesiUjianSaga from './sesi-ujian/saga'
import soalUjianSaga from './soal-ujian/saga'
import kategoriSoalSaga from './kategori-soal/saga'
import pesertaUjianSaga from './peserta-ujian/saga'
import pesertaLulusSaga from './peserta-lulus/saga'
import pesertaDaftarUlangSaga from './peserta-daftar-ulang/saga'
import ujianPesertaSaga from './ujian-peserta/saga'
import peringkatUjianSaga from './peringkat-ujian/saga'
import laporanPesertaSaga from './laporan-peserta/saga'
import notifikasiSaga from './notifikasi/saga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    sesiUjianSaga(),
    soalUjianSaga(),
    kategoriSoalSaga(),
    pesertaUjianSaga(),
    pesertaLulusSaga(),
    pesertaDaftarUlangSaga(),
    ujianPesertaSaga(),
    peringkatUjianSaga(),
    laporanPesertaSaga(),
    notifikasiSaga()
  ])
}